import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67b2e200 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _0914c398 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _661c2602 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _7894ffa2 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7ae4e6bd = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _54b7169a = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _acd16afc = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _e93afe48 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _11b6b53f = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _7caac83b = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _5362b895 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _2b9c4e40 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _20d07346 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _7f7c7bb6 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _4aac65e1 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _926f5a88 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _03be2a74 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _ef493c88 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _e5938c74 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _a27550d2 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _67b2e200,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _0914c398,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _661c2602,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _7894ffa2,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _7ae4e6bd,
    name: "receipt"
  }, {
    path: "/search",
    component: _54b7169a,
    name: "search"
  }, {
    path: "/search-booking",
    component: _acd16afc,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _e93afe48,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _11b6b53f,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _7caac83b,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _5362b895,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _2b9c4e40,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _20d07346,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _7f7c7bb6,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _4aac65e1,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _926f5a88,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _03be2a74,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _ef493c88,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _e5938c74,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _a27550d2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
